<template>
  <div>
    <Main class="general-background">
      <div class="generate-portfolio-main">
        <p>{{ $t("generate_portfolio.text") }}</p>
        <div>
          <div class="ranges-grid">
            <base-slider
              v-if="
                authStore.config?.basic_porfolio_generation_control[
                  'Region'
                ]
              "
              class="the-slider"
              :label="$t('generate_portfolio.region')"
              v-model="portfolioLimitStore.sliders.NA"
              :steps="1"
              :ranges="translateRegionRanges(ranges['Region'])"
              :max="ranges.Region?.length - 1 || 0"
            ></base-slider>
            <base-slider
              v-if="
                authStore.config?.basic_porfolio_generation_control['Size']
              "
              class="the-slider"
              :label="$t('generate_portfolio.size')"
              v-model="portfolioLimitStore.sliders.SZ"
              :steps="1"
              :ranges="translateSizeRanges(ranges['Size'])"
              :max="ranges['Size'].length - 1 || 0"
            ></base-slider>
            <base-slider
              v-if="
                authStore.config?.basic_porfolio_generation_control[
                  'Factor'
                ]
              "
              class="the-slider"
              :label="$t('generate_portfolio.factor')"
              v-model="portfolioLimitStore.sliders.FA"
              :steps="1"
              :ranges="translateFactorRanges(ranges['Factor'])"
              :max="ranges['Factor'].length - 1"
            ></base-slider>
            <base-slider
              v-if="
                authStore.config?.basic_porfolio_generation_control[
                  'Duration'
                ]
              "
              class="the-slider"
              :label="$t('generate_portfolio.duration')"
              v-model="portfolioLimitStore.sliders.DT"
              :steps="1"
              :ranges="translateDurationRanges(ranges['Duration'])"
              :max="ranges['Duration'].length - 1"
            ></base-slider>
            <base-slider
              v-if="
                authStore.config?.basic_porfolio_generation_control[
                  'Investment_Grade'
                ]
              "
              class="the-slider"
              :label="$t('generate_portfolio.investmentGrade')"
              v-model="portfolioLimitStore.sliders.IG"
              :steps="1"
              :ranges="translateInvestmentGradeRanges(ranges['Investment_Grade'])"
              :max="ranges['Investment_Grade'].length - 1"
            ></base-slider>
            <!-- <base-slider
              v-if="
                authStore.config?.basic_porfolio_generation_control[
                  'Concentrated/Diversified'
                ]
              "
              class="the-slider"
              :steps="1"
              :label="$t('generate_portfolio.concentrated_diversified')"
              v-model="portfolioLimitStore.sliders.CD"
              :ranges="translateRanges(ranges['Concentrated/Diversified'])"
              :max="ranges['Concentrated/Diversified'].length - 1"
            ></base-slider> -->
            <base-slider
              v-if="
                authStore.config?.basic_porfolio_generation_control[
                  'Risk'
                ]
              "
              class="the-slider"
              :steps="1"
              :label="$t('generate_portfolio.risk')"
              v-model="portfolioLimitStore.sliders.RK"
              :ranges="translateRiskRanges(ranges['Risk'])"
              :max="ranges['Risk'].length - 1"
            ></base-slider>

            <a-row align="middle" class="w-100">
              <a-col :xs="20" :md="6">
                <label>{{ $t("generate_portfolio.invest_amount") }}</label>
                <a-input
                  v-model:value="portfolioLimitStore.invest_amount"
                  type="number"
                  min="0"
                  class="w-100"
                  placeholder=""
                  style="height: 45px"
                />
              </a-col>
              <!-- <a-col class="pt-4" :md="{ span: 17, offset: 1 }" :xs="24">
                <a-checkbox v-model:checked="portfolioLimitStore.only_my_bank">
                  {{ $t("generate_portfolio.only_my_bank") }}
                </a-checkbox>
              </a-col> -->
            </a-row>
          </div>
        </div>
        <a-row align="start" class="my-3">
          <a-col :span="24">
            <sdButton
              size="small"
              class="element-start"
              type="secondary"
              @click="generatePortfolio"
              :loading="portfolioLimitStore.loading"
            >
              {{ $t("generic.buttons.generate_portfolio") }}
            </sdButton>
          </a-col>
        </a-row>
      </div>
      <!-- </a-space> -->
    </Main>
  </div>
</template>

<script setup>
import { Main } from "../styled";
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import BaseSlider from "@/components/wiseral/BaseSlider";
import { useAuthStore } from "@/stores/AuthStore";
import { useRouter } from "vue-router";
import { usePortfolioLimitStore } from "@/stores/PorfolioLimitsStore";
import { usePortfolioStore } from "@/stores/PortfolioOverviewStore";

const authStore = useAuthStore();
const router = useRouter();
const portfolioLimitStore = usePortfolioLimitStore();
const portfolioStore = usePortfolioStore();
const { t } = useI18n();

const ranges = computed(
  () => authStore.config?.basic_porfolio_generation_control || {}
);

const generatePortfolio = async () => {
  const result = await portfolioLimitStore.generatePortfolio();
  
  if (result) {
    portfolioStore.portfolio_type = 1;
    portfolioStore.original_portfolio_name = null;
    portfolioStore.portfolio_id = null;
    portfolioStore.last_update_date = null;
    portfolioStore.selectedPortfolio = result;
    router.push({
      name: "portfolio-overview-ids",
      params: { ids: portfolioStore.selectedPortfolioIds.join(",") },
    });
  }
};
// const translateRanges = (ranges) =>
//   ranges.map((range) => t(`generate_portfolio.slider_ranges.${range}`, range));
const translateRegionRanges = (ranges) =>
  ranges.map((range) => t(`generate_portfolio.region_slider_ranges.${range}`, range));
const translateSizeRanges = (ranges) =>
  ranges.map((range) => t(`generate_portfolio.size_slider_ranges.${range}`, range)); 
const translateFactorRanges = (ranges) =>
  ranges.map((range) => t(`generate_portfolio.factor_slider_ranges.${range}`, range));
const translateRiskRanges = (ranges) =>
  ranges.map((range) => t(`generate_portfolio.risk_slider_ranges.${range}`, range));
const translateDurationRanges = (ranges) =>
  ranges.map((range) => t(`generate_portfolio.duration_slider_ranges.${range}`, range));
const translateInvestmentGradeRanges = (ranges) =>
  ranges.map((range) => t(`generate_portfolio.ig_slider_ranges.${range}`, range));           
onMounted(() => {
  portfolioLimitStore.invest_amount =
    authStore.config["default_investment_amount"];
});
</script>

<style scoped lang="scss">
.general-background {
  background-color: inherit;
}
.ranges-grid {
  margin: 0px auto 30px auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & > * {
    width: 100%;
    padding: 15px 0;
  }
}

@media only screen and (min-width: 1149px) {
  .ranges-grid {
    margin: 40px auto 40px auto;
    max-width: 80vw;

    & > * {
      width: 47%;
      padding: 20px;
    }
  }
}

@media only screen and (min-width: 2500px) {
  .ranges-grid {
    display: flex;
    max-width: 60vw;

    & > * {
      width: 40%;
      padding: 20px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ranges-grid {
    & > * {
      min-width: 100%;
      padding: 20px;
    }
  }
  .the-slider {
    display: block;
    margin: 10px 0;
    min-width: 100%;

    :deep(span) {
      display: block;
      min-width: max-content;
    }

    :deep(.slider-base) {
      margin-top: 20px;
      min-width: 70vw;
    }
  }
}

@media only screen and (max-width: 380px) {
  .ranges-grid {
    & > * {
      padding: 20px 10px;
    }
  }
}
</style>
